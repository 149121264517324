import React from "react";
import "./Options.css";
import opcion1 from "../assets/img/SERVICIOS 1.png";
import opcion2 from "../assets/img/SERVICIOS 2.png";
import opcion3 from "../assets/img/SERVICIOS 3.png";
import opcion4 from "../assets/img/SERVICIOS 4.png";


const Options = () => {
    return (
        <div className="main_options_section">
            <div className="options_section">
                <div className="options_items">
                    <img src={opcion1} alt="obra nueva" />
                    <h1>OBRA NUEVA</h1>
                    <h2>Desde la primera idea hasta el final de la obra</h2>
                </div>
                <div className="options_items">
                    <img src={opcion2} alt="remodelacion" />
                    <h1>REMODELACIÓN Y AMPLIACIÓN</h1>
                    <h2>Reinventá y potenciá tus espacios</h2>
                </div>
                <div className="options_items">
                    <img src={opcion3} alt="desarrollo inmobiliario" />
                    <h1>DESARROLLOS INMOBILIARIOS</h1>
                    <h2>Desde la búsqueda del terreno hasta el diseño de la resolución más competitiva</h2>
                </div>
                <div className="options_items">
                    <img src={opcion4} alt="proyectos" />
                    <h1>PROYECTOS A DISTANCIA</h1>
                    <h2>Que la distancia no sea un impedimento para elegirnos</h2>
                </div>
            </div>
        </div>
    )
};



export default Options;