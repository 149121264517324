import React from "react";
import marina from "../assets/img/NOSOTROS 1.png";
import pedro from "../assets/img/NOSOTROS 2.png";
import eduardo from "../assets/img/NOSOTROS 3.png";

import "./Nosotros.css";

const Nosotros = () => {
    return (
        <div className="nosotros_section">
            <h1>Nosotros</h1>
            <div className="staff_section">
                <div className="staff">
                    <img src={marina} alt="marina landó" />
                    <h2>MARINA MONTI LANDÓ</h2>
                    <h3>Arquitecta FADU UBA 1987</h3>
                    <h4>“ Me encanta interpretar
                        los sueños de un cliente
                        y traducirlos en una
                        espacialidad
                        tan rica como inimaginada”
                    </h4>
                    <span></span>
                </div>
                <div className="staff">
                    <img src={pedro} alt="pedro landó" />
                    <h2>PEDRO LANDÓ</h2>
                    <h3>“El Tuco”
                        Arquitecto FADU UBA 2019
                    </h3>
                    <h4>“Mi lugar es en la obra, ahí donde las ideas se materializan. 
                        También me gusta estar  formado en lo último en tecnología digital.”
                    </h4>
                    <span></span>
                </div>
                <div className="staff">
                    <img src={eduardo} alt="eduardo orué" />
                    <h2>EDUARDO ORUÉ</h2>
                    <h3>“Edu”
                        Arquitecto FADU UBA 2019
                    </h3>
                    <h4>“ Me interesa el espacio interior en donde los detalles tienen un especial 
                        protagonismo. Esta mirada también la llevo a la fotografía, otra de mis pasiones” 
                    </h4>
                    <span></span>
                </div>
            </div>
        </div>
    );
};

export default Nosotros;