import React from "react";
import logo from "../assets/img/LOGO 2.png";

import "./Footer.css";



const Footer = () => {
    return (
        <div className="main_footer">
            <div className="footer_container">
                <div className="footer_col_1">
                    <img src={logo} alt="logo" />
                </div>
                <div className="footer_col_2">
                    <h1>11 de Septiembre de 1888, 4596, Nuñez, Buenos Aires, Argentina.
                    </h1>
                </div>
                <div className="footer_col_3">
                        <h4>marinalando@gmail.com</h4>
                        <h4>+54 11 5713 9784</h4>
                </div>  
            </div>
        </div>
    )
};



export default Footer;