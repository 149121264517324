import React from "react";
import "./Base.css";
import base1 from "../assets/img/BASES-1.png";
import base2 from "../assets/img/BASES-2.png";
import base3 from "../assets/img/BASES-3.png";




const Base = () => {
    return (
        <div className="main_base">
            <div className="base_item">
                <div className="main_text_base">
                    <h1>Una estética que identifique al cliente</h1>
                    <div className="gris_texto_base">
                        <h2>Expresión de una inquietud espacial creativa para vivir con Arte.
                        Un contrapunto de visuales y experiencias que mezclen espacio
                        interior y paisaje, convirtiendo las ideas en materialidad, textura y color.
                        </h2>
                    </div>
                </div>
                <div className="base_img">
                    <img className="img_der" src={base1} alt="" />
                </div>
            </div>
            <div className="base_item_2">
                <div className="base_img">
                    <img className="img_izq" src={base2} alt="" />
                </div>
                <div className="main_text_base_segundo">
                    <h1>Un modo de vivir personal</h1>
                    <div className="gris_texto_base_segundo">
                        <h2>Brindamos soluciones particulares no 
                            estandarizadas que se ajustan a los anhelos y necesidades del cliente.
                        </h2>
                    </div>
                </div>
            </div>
            <div className="base_item">
                <div className="main_text_base">
                    <h1>Diseño Bioclimático y sustentabilidad</h1>
                    <div className="gris_texto_base">
                        <h2>Arquitectura diseñada con luz natural, aire y vegetación, en la 
                            aplicación de tecnologías y energías renovables 
                            para el cuidado del ambiente y una vida más sana. 
                            </h2>
                    </div>
                </div>
                <div className="base_img">
                    <img className="img_der" src={base3} alt="" />
                </div>
            </div>
        </div>
    )
};




export default Base;