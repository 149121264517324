import React, {useState} from 'react';
import { Link } from "react-scroll";
import Logo from "../assets/img/LOGO.png";
import SideDrawer from "./Sidedrawer";
import { Squeeze as Hamburger } from 'hamburger-react'


import './Navbar1.css';


const Navbar = () => {


  const [sidebarOpen, setSidebarOpen] = useState(false)

  const openHandler = () => {
    if (!sidebarOpen) {
      setSidebarOpen(true) 
    } else {
      setSidebarOpen(false)
    }
  }

  let sidebar 
  if (sidebarOpen) {
    sidebar = <SideDrawer />
  }

  return (
    <div>
      <div className="main_bar" >
      < div className="burger_navbar">
          </div>

        <div className="logo_bar" >
          <img src={Logo} alt="logo" />
        </div>
        <div className="section_bar_options" >
          <Link activeClass="active"
            to="proyectos"
            spy={true}
            smooth={true}
            hashSpy={true}
            offset={50}
            duration={1300}
            delay={0}
            className="nav_bar_options"
          >
            <h1>PROYECTOS</h1>
            </Link>
          <Link activeClass="active"
            to="nosotros"
            spy={true}
            smooth={true}
            hashSpy={true}
            offset={50}
            duration={1300}
            delay={0}
            className="nav_bar_options"
          >
            <h1>NOSOTROS</h1>
            </Link>
          <Link activeClass="active"
            to="novedades_link"
            spy={true}
            smooth={true}
            hashSpy={true}
            offset={50}
            duration={1300}
            delay={0}
            className="nav_bar_options"
          >
            <h1>NOVEDADES</h1>
            </Link>
          <Link activeClass="active"
            to="contact_menu"
            spy={true}
            smooth={true}
            hashSpy={true}
            offset={50}
            duration={1300}
            delay={0}
            className="nav_bar_options"
          >
            <h1>CONTACTENOS</h1>
            </Link>
        </div>
        <div className="burger_navbar">
          <a href="/#" className="burger_btn" onClick={openHandler}> 
          <Hamburger color="gray" rounded label="show menu"/>
          </a>
        </div>
      </div>
      {sidebar}
    </div>
  )
};



export default Navbar; 