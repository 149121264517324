import React from "react";
import banner from "../assets/img/portada.jpg";

import "./Proyectos.css";


const Proyectos = () => {
    return (
        <div className="proyectos_main" id="proyectos"> 
            <h1>Proyectos</h1>
            <img src={banner} alt="proyectos" />
        </div>
    );
};


export default Proyectos;