import React from "react";
import Maqueta from "../assets/img/foto.gif";

import "./Modo.css";


const Modo = () => {
    return (
        <div className="modo_main" id="nosotros">
            <div className="modo_img">
                <img src={Maqueta} alt="house blue prints" />
            </div>
            <div className="nuestro_modo">
                <h1>Nuestro modo de trabajar</h1>
            </div>
            <div className="modo_paneles">
                <div className="panel_margen">
                    <div className="panel_rep">
                        <h2>Con creatividad,
                            sensibilidad y
                            experiencia, 
                            convertimos en 
                            soluciones innovativas
                            e integrales cualquier
                            desafío que nos plantee
                            el cliente. Trabajamos
                            junto a él en 
                            permanente
                            dialogo.
                        </h2>
                    </div>
                    <div className="panel_rep">
                        <h2>Diseñamos una estética 
                            que lo identifique, en la 
                            cual una espacialidad 
                            moderna, la
                            funcionalidad y los
                            componentes
                            ambientales son un 
                            denominador común.
                        </h2>
                    </div>
                    <div className="panel_rep">
                        <h2>Nuestro trabajo 
                            comienza en la
                            elección del terreno
                            hasta el diseño y 
                            dirección ejecutiva de 
                            la obra, incluyendo sus 
                            últimos detalles de 
                            arquitectura interior y  exterior
                        </h2>
                    </div>
                </div>
                <h3>Además!</h3>
                <h4>Optimizamos energéticamente nuestras obras a través del uso
                    de energías renovables, aplicando tecnologías innovadoras y conceptos
                    bioclimáticos tradicionales.
                    De esta forma aumenta la ventilación, la luz natural y el confort.
                </h4>
            </div>
        </div>
    )
};


export default Modo; 