import React from 'react';
import emailjs from 'emailjs-com';

import './Contact.css';

const Contact = (e) => {
    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('gmail', 'template_9aw43ln', e.target, 'user_rjNmbpfnRjP3yvNmOlo1F')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
    }

    return (
        <form className="contact-form" onSubmit={sendEmail} id="contact_menu">
            <div className="other_color_rectangle"></div>
            <div className="contact_sections" id="contact_1">
                <input type="text" name="phone" placeholder="Teléfono" required/>                
            </div >
            <div className="contact_sections">
                <input  type="email" name= "email" placeholder="tu@mail.com" required/>
            </div>
            <div className="contact_sections">
                <textarea name="mensaje" placeholder="No dudes en contactarnos!"/>
            </div>
            <input type="submit" value="Enviar" className="contact_btn" />
        </form>
    );  
}




export default Contact;