import React from "react";
import novedad from "../assets/img/NOVEDADES.png";
import { Link } from "react-scroll";
import chevron from "../assets/img/chevron.png";

import "./Novedades.css"



const Novedades = () => {
    return (
        <div className="novedades_main" id="novedades_link">
            <h1>Novedades</h1>
            <div className="novedades_container">
                <div className="novedades_column_1">
                    <img src={novedad} alt="reunión" />
                </div>
                <div className="novedades_column_2">
                    <h2>COMPROMISO AMBIENTAL</h2>
                    <h3>Invitados por el gerente de obras particulares de Nordelta,
                    nuestro estudio participó de un programa de formación,
                    enfocado al compromiso ambiental. Asistimos a 42 talleres en el Centro de Diseño Ambiental en el local de Puertos. Se reunió en  este ámbito concreto y en forma permanente la oferta de tecnología y productos relacionados con la arquitectura
                    sustentable.
                    ble especialmente dedicada a la vivienda y edificios mixtos.
                    </h3>
                </div>
            </div>
            <div className="btn_arriba_novedades">
                <div className="boton_para_arriba">
                    <Link activeClass="active"
                        to="cover_menu"
                        spy={true}
                        smooth={true}
                        hashSpy={true}
                        offset={50}
                        duration={1300}
                        delay={0}
                        
                    >
                        <div className="volver_al_inicio">
                            <img id="img_chevron" src={chevron} alt="chevron up" />
                            <h1>Volver al Inicio</h1>
                        </div>

                    </Link>
                </div>

            </div>
        </div>
    )
};



export default Novedades;