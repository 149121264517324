import React from 'react';
import cliente1 from "../assets/img/CLIENTES 1.png";
import cliente2 from "../assets/img/CLIENTES 2.png";
import cliente3 from "../assets/img/CLIENTES 3.png";
import cliente4 from "../assets/img/CLIENTES 4.png";
import cliente5 from "../assets/img/CLIENTES 5.png";
import cliente6 from "../assets/img/CLIENTES 6.png";
import cliente7 from "../assets/img/CLIENTES 7.png";
import cliente8 from "../assets/img/CLIENTES 8.png";
import cliente9 from "../assets/img/CLIENTES 9.png";
import cliente10 from "../assets/img/CLIENTES 10.png";
import cliente11 from "../assets/img/CLIENTES 11.png";
import cliente12 from "../assets/img/CLIENTES 12.png";
import cliente13 from "../assets/img/CLIENTES 13.png";
import cliente14 from "../assets/img/CLIENTES 14.png";


import './Clientes.css';


const Clientes = () => {
    return (
        <div className="clientes_container">
            <h1>Algunos de nuestros clientes</h1>
            <div className="container_logos">
                <div className="clientes_logos">
                    <img src={cliente1} alt="nordelta" />
                    <img src={cliente2} alt="los castores" />
                    <img src={cliente3} alt="los lagos" />
                    <img src={cliente4} alt="cabos del lago" />
                    <img src={cliente5} alt="golf" />
                    <img src={cliente6} alt="santa barbara" />
                    <img src={cliente7} alt="santa maria de tigre" />
                    <img src={cliente8} alt="san agustin" />
                    <img src={cliente9} alt="los castaños nordelta" />
                    <img src={cliente10} alt="boating club" />
                    <img src={cliente11} alt="haras del pilar" />
                    <img src={cliente12} alt="las garzas" />
                    <img src={cliente13} alt="san isidro" />
                    <img src={cliente14} alt="vicente lopez" />
                </div>
            </div>
        </div>
    )
};


export default Clientes; 