import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import cover1 from '../assets/img/PORTADA-1.jpg';
import cover2 from '../assets/img/PORTADA-2.jpg';
import cover3 from '../assets/img/PORTADA-3.jpg';
import cover4 from '../assets/img/PORTADA-4.jpg';

import './Cover.css';


const Cover = () => {
  return (
    <div className="cover_section" id="cover_menu">
      <Carousel>
        <Carousel.Item className="img_cover">
          <img className="d-block w-100"  src={cover1} alt="First slide" />
          <Carousel.Caption>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100"   src={cover2}  alt="Third slide"  />
          <Carousel.Caption>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={cover3} alt="Third slide"  />
          <Carousel.Caption>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100"  src={cover4}  alt="fourth slide" />
          <Carousel.Caption>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  )
};




export default Cover;