import React from 'react';
import './App.css';

import Cover from './components/Cover';
import Options from "./components/Options";
import Base from "./components/Base";
import Clientes from "./components/Clientes";
import Novedades from "./components/Novedades";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import Navbar1 from './components/Navbar1';
import Modo from "./components/Modo";
import Nosotros from "./components/Nosotros";
import Proyectos from "./components/Proyectos";


function App() {
  return (
    <div className="App">
      <Navbar1 />  
      <Cover />
      <Options />
      <Base />
      <Proyectos />
      <Clientes />
      <Modo />
      <Nosotros />
      <Novedades />
      <Contact />
      <Footer />
    </div>
  )
}


export default App
