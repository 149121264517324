import React from "react";
import { Link } from "react-scroll";


import "./Sidedrawer.css";

const drawer = props => (
    <nav className="drawer_main" >
        <ul>
        <div className="sideDrawer_options" >
        <Link activeClass="active"
            to="proyectos"
            spy={true}
            smooth={true}
            hashSpy={true}
            offset={50}
            duration={1300}
            delay={0}
            className="nav_bar_options"
          >
            <h1>PROYECTOS</h1>
            </Link>
          <Link activeClass="active"
            to="nosotros"
            spy={true}
            smooth={true}
            hashSpy={true}
            offset={50}
            duration={1300}
            delay={0}
            className="nav_bar_options"
          >
            <h1>NOSOTROS</h1>
            </Link>
          <Link activeClass="active"
            to="novedades_link"
            spy={true}
            smooth={true}
            hashSpy={true}
            offset={50}
            duration={1300}
            delay={0}
            className="nav_bar_options"
          >
            <h1>NOVEDADES</h1>
            </Link>
          <Link activeClass="active"
            to="contact_menu"
            spy={true}
            smooth={true}
            hashSpy={true}
            offset={50}
            duration={1300}
            delay={0}
            className="nav_bar_options"
          >
            <h1>CONTACTENOS</h1>
            </Link>
        </div>
        </ul>
    </nav>
)

export default drawer;